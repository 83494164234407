import React from 'react';
import { createStore, applyMiddleware, compose,combineReducers } from 'redux';
import { Map } from 'immutable';
import reducer from './reducers';
import AllRoutes from "./routes/Routes";
import {Provider} from "react-redux";
import createSagaMiddleware from 'redux-saga';
import Sagas from "./sagas"
const App =()=>{
    const reducers = combineReducers({
        ...reducer,
    });
    const sagaMiddleWare = createSagaMiddleware({

    });


    const store = createStore(
        reducers,
        {},
        compose(
            applyMiddleware(sagaMiddleWare)
        )
    );
    sagaMiddleWare.run(Sagas)
    return <div className="App">
        <Provider store={store}>
            <AllRoutes />
        </Provider>

    </div>
}

export default App;
