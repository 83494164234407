import React, { useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import '../../css/style.css';

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'About', link: '/about' },
    { text: 'Donation', link: '/donation' },
    // Add more pages as needed
    { text: 'Literature', link: '/literature' },
    { text: 'Media', link: '/media' },
    { text: 'Events', link: '/events' },
    { text: 'Contact Us', link: '/contact' },
  ];

  return (
    <>
      <AppBar position="static" className="header">
        <Container>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>

            {/* Logo */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src="/Logo/logo.png" alt="Logo" className="logo" />
            </Typography>

            <Hidden smDown>
              {/* Desktop menu items */}
              <Box sx={{ display: 'flex' }} className="menuitems">
                {menuItems.map((item, index) => (
                  <RouterLink
                    key={item.text}
                    to={item.link}
                    style={{
                      textDecoration: 'none',
                      color: currentPath === item.link ? '#FB521D' : '#33638B',
                    }}
                  >
                    {item.text}
                  </RouterLink>
                ))}
              </Box>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer for mobile view */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem button key={item.text}>
              <RouterLink
                to={item.link}
                style={{
                  textDecoration: 'none',
                  color: currentPath === item.link ? 'orange' : 'inherit',
                }}
              >
                {item.text}
              </RouterLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div>
        <Link href="/donation" className="buttondonation">
          Donation
      </Link>
      </div>
    </>
  );
};

export default Header;
