import { takeLatest, put } from 'redux-saga/effects';
import {GETJSONDATA} from "../actions/types"
import myData from '../data/data.json';
import {getJsonData} from "../actions/index"
export function* getJsonDataSaga() {
    try {
        yield put(getJsonData.success(myData));
    } catch (err) {

    }
}

export default function*() {
    yield takeLatest(GETJSONDATA.PENDING, getJsonDataSaga);
}
