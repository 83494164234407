import React from 'react';
import { Container, Typography, Card, CardContent, CardMedia, Grid, Box } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';


const EventsPage = () => {
  return (
    <Box>
     {/* Header */}
     {<Header/>}
      {/* Main Content */}
      <Box className="about_section">

<Container>
<Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
Media
    </Typography>
</Container>
</Box>
    <Container>
   <Box className="section_three new_section2 paddmain">
       <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">

Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src=" /Events/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="Time2">
                                09
                        </Typography>
                        <Typography variant="h5" className="Time2">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="Time3">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                  </Grid>
      </Box>
    </Container>
    {<Footer/>}

    </Box>
  );
};

export default EventsPage;
