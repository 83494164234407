// MainPage.js
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';


const MainPage = () => {
  return (
    <Box>
     {<Header/>}
      {/* Main Content */}

      <Box className="section_two">

  
        <Box>
      <Carousel showArrows={true} infiniteLoop={true} autoPlay={true} interval={3000} showThumbs={false}>
        <div>
          <img src="/Home/rg1.png" alt="Image 1" />
          <div className="mainhead">
          <div className="slidecontent">
            {/* Add your main content here */}
            <Typography className="h4Slider" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
              Shree Nangartas Santhan
            </Typography>
            <Typography className="perasldier" variant="p" sx={{ marginTop: 2 }}>
              This is the very famous temple located near Jalna, Maharastra. This temple is constructed in 1897 by Shri. Mahant Ramgiriji Maharaj.
            </Typography>
           <Link href="/Templedetail" className="removedecoration"> <Button className="read_more" variant="contained">Read More</Button></Link>
          </div>
          </div>
        </div>
        <div>
          <img src="/Home/rg1.png" alt="Image 2" />
          <div className="mainhead">
          <div className="slidecontent">
            {/* Add your main content here */}
            <Typography className="h4Slider" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
            Shree Omkareshwar Sansthan
            </Typography>
            <Typography className="perasldier" variant="p" sx={{ marginTop: 2 }}>
              This is the very famous temple located near Jalna, Maharastra. This temple is constructed in 1897 by Shri. Mahant Ramgiriji Maharaj.
            </Typography>
            <Link href="/Templedetail" className="removedecoration"> <Button className="read_more" variant="contained">Read More</Button></Link>

          </div>
          </div>
        </div>
        <div>
          <img src="/Home/rg1.png" alt="Image 3" />
          <div className="mainhead">
          <div className="slidecontent">
            {/* Add your main content here */}
            <Typography className="h4Slider" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
            Sanskar Prabhodhini Gurukul
            </Typography>
            <Typography className="perasldier" variant="p" sx={{ marginTop: 2 }}>
              This is the very famous temple located near Jalna, Maharastra. This temple is constructed in 1897 by Shri. Mahant Ramgiriji Maharaj.
            </Typography>
            <Link href="/Templedetail" className="removedecoration"> <Button className="read_more" variant="contained">Read More</Button></Link>

          </div>
          </div>
        </div>
      </Carousel>
    </Box>

      </Box>
      {/* Footer */}
      <Box className="bgwhite">
        <Container>
          <Typography variant="h3" className="about_heading">
            About
          </Typography>
          <Typography variant="p" className="about_heading2">
            Shree Ramgiriji maharaj born in Nangartas in 1870. This is description about the shree Ramgiriji Maharaj short history and main highlight short description. This is the short description about the Ramgiriji Maharaj. This is the short descroption about the ramgiri maharaj.
          </Typography>
          <Typography variant="p" className="about_heading2">
            Shree Ramgiriji maharaj born in Nangartas in 1870. This is description about the shree Ramgiriji Maharaj short history and main highlight short description.
          </Typography>
        </Container>

        <Box className="bgwhite2">
          <Container className="container_size">
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <div>
                  <img src="/Home/a1.png" className="img_about" />
                </div>
              </Grid>
              <Grid item xs={4} md={4}>
                <div>
                  <img src="/Home/a2.png" className="img_about" />
                </div>
              </Grid>
              <Grid item xs={4} md={4}>
                <div>
                  <img src="/Home/a3.png" className="img_about" />
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box className="bgwhite2 mt-05">
        <Container>
        <div className="shadow">
        <div className="borderTwo">
          <Grid container spacing={2} >
            <Grid item xs={12} md={5}>
            <Box className="timing">
                <Typography variant="h5" className="timing_heading">
                Nangartas Santhan Daily Program                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time_heading">
            Time
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme_heading">
                    Programme
          </Typography>
                    </Grid>    
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>  04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Temple Open
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>     05:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Kakad Aarati (Morning)
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   07:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Bhajan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   08:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Prasad
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   09:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Bhojan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>  10:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Kirtan
          </Typography>
                    </Grid>    
                  </Grid>
              </Box>

            </Grid>
            <Grid item xs={12} md={2} className="hidemobile">
              <div className="Bordercenter"></div>
            </Grid>

            <Grid item xs={12} md={5} >
              <Box className="timing">
                <Typography variant="h5" className="timing_heading">
                Omkareshwar Santhan Daily Program
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time_heading">
            Time
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme_heading">
                    Programme
          </Typography>
                    </Grid>    
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>  04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Temple Open
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>     05:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Kakad Aarati (Morning)
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   07:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Bhajan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   08:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Prasad
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>   09:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Bhojan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                    <Typography variant="h6" className="Time">
                    <i class="bi bi-clock"></i>  10:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={2}>
                    <i class="bi bi-arrow-right"></i>
                    </Grid>
                    <Grid item xs={5} className="righttext">
                    <Typography variant="h6" className="Programme">
                    <i class="bi bi-brightness-high"></i>  Kirtan
          </Typography>
                    </Grid>    
                  </Grid>
              </Box>

            </Grid>
          </Grid>
       </div>
       </div>
        </Container>
      </Box>

        <Box className="bgwhite2 mb-150">
        <Container>
          <div className="events">
            <div className="headingTemple">
            <Typography variant="h4" className=" about_heading marginmdsnj">
                  Upcoming Events
          </Typography>
          <Typography variant="body" className="Programme">
          Enthusiastically underwhelm quality benefits rather than professional outside the box thinking. Distinctively network highly efficient leadership skills


          </Typography>
            </div>
          <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <div className="eventsunder">
                          <img src="/Home/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="">
                                09
                        </Typography>
                        <Typography variant="h5" className="">

Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src="/Home/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="">
                                09
                        </Typography>
                        <Typography variant="h5" className="">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>   
                    <Grid item xs={12} md={4}>
                    <div className="eventsunder">
                          <img src="/Home/hindu-hero-bg-01.png" className="imgevents" />
                          <div className="datetime">
                          <Typography variant="h5" className="">
                                09
                        </Typography>
                        <Typography variant="h5" className="">
                          
Nov                        </Typography>
                            </div>
            
                          <Typography variant="h5" className="">
                                Recent Events
                        </Typography>
                        <p>

                        <i class="bi bi-clock"></i>     10:00 AM - 12:00 AM
                                </p>
                        <p>

                        <i class="bi bi-geo-alt"></i> PO Box 16122, Collins Street
                        </p>
                       <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore consectetur adipisicing elit

                        </p>
                      </div>
                    </Grid>    
                  </Grid>
          </div>
          </Container>
          </Box>
 
      {<Footer/>}
      </Box>
  );
};

export default MainPage;
