// ContactPage.js
import React from 'react';
import { Container, Typography, Grid, TextField, Button, Box } from '@mui/material';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const ContactPage = () => {
  return (
    <Box>
      {<Header />}
      <Box className="about_section">

<Container>
<Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
Contact Us
    </Typography>
</Container>
</Box>

    <Container>
    <Box className="section_three new_section">
    

    <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
         
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField variant="standard" label="Name" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField variant="standard" label="Email" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField variant="standard" label="Mobile Number" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField variant="standard" label="Message" multiline rows={4} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" className="read_more">
            Send Message
          </Button>
        </Grid>
      </Grid>

        </Grid>
        <Grid item xs={12} sm={6}>
  {/* Google Map Embed Code */}
  <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1dYOUR_MAP_COORDINATES!2dLONGITUDE!3dLATITUDE!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3AYOUR_PLACE_NAME!2sYOUR_PLACE_NAME!5e0!3m2!1sen!2sus!4vYOUR_MAP_VERSION"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>        </Grid>
   
      </Grid>
    <div className="contactbox">
              
    <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <div className="cardaddress">
            <img src="/Contact/location-pin.png" className="imgmap" />
          <Typography variant="h6" className="donation_content webvi">
         Address </Typography>  
         <Typography variant="body" className="donation_content23">
         suite/unit number, as appropriate; city; Province or State as appropriate; </Typography>  
          </div>
          </Grid>
        <Grid item xs={12} sm={4}>
        <div className="cardaddress">
        <img src="/Contact/phone.png" className="imgmap" />

          <Typography variant="h6" className="donation_content webvi">
         Mobile Number </Typography>  
         <Typography variant="body" className="donation_content23">
         +91 5863265621 </Typography>  
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
        <div className="cardaddress">
        <img src="/Contact/whatsapp.png" className="imgmap" />

          <Typography variant="h6" className="donation_content webvi" >
         Whats App Number </Typography>  
         <Typography variant="body" className="donation_content23">
         +91 9585256626 </Typography>  
          </div>      </Grid>

      </Grid>
    </div>

    
          </Box>

    </Container>
    {<Footer />}

    </Box>
  );
};

export default ContactPage;
