// MainPage.js
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Slider from 'react-slick';
import  Paper from '@mui/material/Paper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../css/style.css';


const slides = [
  {
    title: 'Shree Nangartas Santhan',
    description: 'This is the very famous temple located near Jalna, Maharastra. This temple is constructed in 1897 by Shri. Mahant Ramgiriji Maharaj.',
  },
  {
    title: 'Slide 2',
    description: 'Description for Slide 2',
  },
  // Add more slides as needed
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};

const MainPage = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box>
      {/* Header */}

      <AppBar position="static" className="header">
        <Container>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img src="/logo.png" className="logo" />
            </Typography>
            <Hidden smDown>
              {/* Add your menu items for desktop size */}
              <Box sx={{ display: 'flex' }} className="menuitems">
                <Link  href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Home
                </Link>
                <Link className="active" href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  About
                </Link>
                <Link href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Donation
                </Link>
                <Link href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Literature
                </Link>
                <Link href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Media
                </Link>
                <Link href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Events
                </Link>
                <Link href="#" color="inherit" sx={{ marginLeft: 2 }}>
                  Contact Us
                </Link>
              </Box>
            </Hidden>
          </Toolbar>
        </Container>

      </AppBar>
      {/* Sidebar */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {['Home', 'About', 'Donation', 'Literature', 'Media', 'Events', 'Contact Us'].map((text, index) => (
            <ListItem button key={text}>
              <Link href="#" color="inherit">
                {text}
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}

      <Box className="slider_section">
        <Container>
      <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index}>
          <div className="typoslider" style={{ padding: '16px' }}>
            <Typography variant="h3">{slide.title}</Typography>
            <Typography variant="body1">{slide.description}</Typography>
            <Button variant="contained" className="button_readmore2">Read More</Button>
          </div>
        </div>
      ))}
    </Slider>
    </Container>
      </Box>
      <Box>
        <Container>
      <div className="bgwhite3">

          <div>
          <Typography variant="h3" className="about_heading">
            About
          </Typography>
          <Typography variant="p" className="about_heading2">
            Shree Ramgiriji maharaj born in Nangartas in 1870. This is description about the shree Ramgiriji Maharaj short history and main highlight short description. This is the short description about the Ramgiriji Maharaj. This is the short descroption about the ramgiri maharaj.
          </Typography>
          <Button variant="contained" className="button_readmore">Read More</Button>

          <div className="bgchange1">
          <Grid container spacing={2}>
          <Grid item xs={6}>
<img src="/Rectangle 9.png" />
</Grid>
            <Grid item xs={6}>
              <Box className="timing">
                <Typography variant="h5" className="timing_heading">
                  Nangartas Santhan Daily Program
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time_heading">
            Time
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme_heading">
                    Programme
          </Typography>
                    </Grid>    
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
            04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Temple Open
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Kakad Aarati (Morning)
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Bhajan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Prasad
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Bhojan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Kirtan
          </Typography>
                    </Grid>  
                      
                  </Grid>
              </Box>

            </Grid>
            </Grid>
          </div>
          <div className="bgchange2">

            <Grid container spacing={2}>
       
            <Grid item xs={6}>
              <Box className="timing">
                <Typography variant="h5" className="timing_heading">
                  Nangartas Santhan Daily Program
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time_heading">
            Time
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme_heading">
                    Programme
          </Typography>
                    </Grid>    
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
            04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Temple Open
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Kakad Aarati (Morning)
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Bhajan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Prasad
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Bhojan
          </Typography>
                    </Grid>    
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <Typography variant="h6" className="Time">
                    04:00 AM
          </Typography>
                    </Grid>
                    <Grid item xs={6} className="righttext">
                    <Typography variant="h6" className="Programme">
                    Kirtan
          </Typography>
                    </Grid>  
                      
                  </Grid>
              </Box>

            </Grid>
            <Grid item xs={6}>
<img src="/Rectangle 11.png" />
</Grid>

            </Grid>
            </div>
            <div className="bgchange1">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                  <div>
                      <img src="/ganeshji.svg" />
                  </div>
              </Grid>
              <Grid item xs={6}>
                <div className="ganeshjiheading">
                <Typography variant="h5" component="h2" className="mainheading">
                <img src="/icon1.svg" /> Upcoming Event
                </Typography>
                <Typography variant="h5" component="h2" className="heading2">
                Ganpati Utsav
                </Typography>
                <Typography variant="h6" component="h2" className="heading3">
                Date : 12-02-2023
                </Typography>
                <Typography variant="h6" component="h2" className="heading4"> 
                Ganpati Utsav is been celebrated in Omkareshwar santhan. This is one of the most popular events amongs the devotees and it’s been celebrated by more than 11 villages peaple. One of the key highlight about this event is you can see all hindu sadhus who came from different part of India. During this event most of the people donate foods.   One of the key highlight about this event is you can see all hindu sadhus who came from different part of India. During this event mostof the people donate foods. 
                </Typography>
                </div>
              </Grid>
             
            </Grid>
            </div>


          </div>    </div>
        </Container>
      </Box>

  
      {/* Footer */}


      <Box
        component="footer"
        sx={{
          mt: 'auto',
          backgroundColor: '#FBBBA7',
          p: 1,
        }}
      >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={7}>
              <div className="linkfooter">
                <Link href="#">Terms & Conditions </Link>
                <Link href="#">General Information</Link>
                <Link href="#">Contact Us</Link>
              </div>
          </Grid>
          <Grid item xs={5}>
           <div className="socialmedia">
           <Link href="#"><img src="/facebook.png" /></Link>
           <Link href="#"><img src="/insta.png" /></Link>
           <Link href="#"><img src="/youtube.png" /></Link>
           <Link href="#"><img src="/twitter.png" /></Link>

           </div>
          </Grid>
        
        </Grid>
        <Typography variant="h5" className="copyright">
        © 2022 Shree Ramgiriji Sansthan,Nangartas
        </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default MainPage;
