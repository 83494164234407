// MainPage.js
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const MainPage = () => {
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      {/* Header */}
      {<Header/>}
      {/* Main Content */}

      <Box className="about_section">

        <Container>
        <Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
       Donation
            </Typography>
        </Container>
      </Box>
      {/* Footer */}
 <Box>
<Container>
    <div className="section_three paddmain">
      <Box>
        <Typography variant="h6" className="donation_content">
        Various Model Of Donation Available</Typography>   
          <Typography variant="h6" className="donation_content">
          1. Donation Counter And Information Counter </Typography>  
         <ul className="donation_content3">
          <li>Donation counters are available i Omkareshwar Temple premises . Donation counters accept Cash, Cheque, Demand Draft, Debit/Credit Card, Foreign Currency and kind donations.</li>
        <li>The Donation receipts for Cash and kind donations are given immediately and for other kind of donations</li>
         </ul>
         <Typography variant="h6" className="donation_content">
         2. Bank To Bank Transfer </Typography>  
          <div className="bankdetail">




          <Grid container spacing={2}>
              <Grid item xs={3}>
                  <div className="tbodymain">
                  <Typography variant="h6" >
         Account Number</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         Bank Name</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         IFSC Code</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain">
                  <Typography variant="h6" >
         Holder Name</Typography> 
                  </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                  <div className="tbodymain2">
                  <Typography variant="h6" >
         858952181511551</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         State Bank of India</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         STATE0250P</Typography> 
                  </div>
              </Grid>
              <Grid item xs={3}>
              <div className="tbodymain2">
                  <Typography variant="h6" >
         Ram Giri Ji</Typography> 
                  </div>
              </Grid>
            </Grid>
          </div>



          <div className="qrcode">
            <img src="/Donation/paytm.png" />
          </div>
         <Box>
        <Typography variant="h6" className="about_content">
        Shri Sai Baba is revered as one of the greatest saints ever seen in India, endowed with unprecedented powers, and is worshipped as a God incarnate. (SAI meaning Sakshaat Ishwar) - GOD THE ABSOLUTE
          </Typography>
          <Typography variant="h6" className="about_content">
          This mysterious Fakir first made his appearance in Shirdi as a youth and remained there throughout his long life. HE transformed the lives of those who met him and continuously is doing so even after his Samadhi in 1918 for those whose hearts are touched by his love and who pray and call him at any emergency in life for his blessings.</Typography>
          <Typography variant="h6" className="about_content">
          Baba stated that his mission is to “Give Blessings” without discrimination to all, and he proves it in myriad ways by healing the sick, saving lives, protecting the vulnerable, averting accidents, granting offspring, facilitating financial gain, bringing people into harmony within themselves and with each other and, above all, in effecting the spiritual evolution and transformation of those who came to him as the last resort. </Typography>
        </Box>
       
          </Box>
          

      </div>
      </Container>
 </Box>
    {<Footer/>}
    </Box>
  );
};

export default MainPage;
