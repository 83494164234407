/* eslint-disable max-lines */
import * as types from './types';

export const createAction = type => payload => ({ payload, type });
export const createAsyncAction = type => Object.assign(
    createAction(type.PENDING),
    {
        error: payload => ({ error: true, payload, type: type.ERROR }),
        success: payload => ({ payload, type: type.SUCCESS })
    },
    { type }
);


export const getJsonData = createAsyncAction(types.GETJSONDATA);
