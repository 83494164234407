// MainPage.js
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const MainPage = () => {
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      {/* Header */}
      {<Header/>}
      {/* Main Content */}

      <Box className="about_section">

        <Container>
        <Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
        Literature
            </Typography>
        </Container>
      </Box>
      {/* Footer */}
 <Box>
<Container>
       <div className="section_three paddmain">
       <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <CardMedia
        component="video"
        controls
        height="80"
        src="/Literature/demovideo.mp4"
        title="Your Video Title"
      />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <CardMedia
        component="audio"
        controls
        height="200"
        src="/Literature/demovideo.mp4"
        title="Your Video Title"
      />              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
          <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>

            <Grid item xs={12} md={4}>
              <div className="Literature">
              <img src="/Literature/pdf.png" />
              <Typography variant="h3">
              Lorem Ipsum is  
          </Typography>
          <Typography variant="p" className="peraheadingmain">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,          </Typography>
              </div>
          </Grid>
        
        </Grid>
      </div>
      </Container>
 </Box>
    {<Footer/>}
    </Box>
  );
};

export default MainPage;
