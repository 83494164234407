import React from "react"
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import {RoutesData} from "./RouterConstants"

const AllRoutes = () =>{
    return <Router>
        <Routes>
            {
                RoutesData.map(({path,component:Component},index)=>{
                    return <Route path={path} key={index} element={<Component />} />
                })
            }
        </Routes>
    </Router>
}
export default AllRoutes
