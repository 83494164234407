// src/App.js
import React, { useState } from 'react';
import { Grid, Card, CardMedia, Dialog, DialogContent, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const App = () => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = React.createRef();

  const images = [
    { src: '/Media/a1.png' },
    { src: '/Media/a2.png' },
    { src: '/Media/a3.png' },
    { src: '/Media/a2.png' },
    { src: '/Media/a1.png' },
    { src: '/Media/a2.png' },
    { src: '/Media/a3.png' },
    { src: '/Media/a2.png' },
    // Add more images as needed
  ];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setShowCarousel(true);
  };

  const handleCloseCarousel = () => {
    setShowCarousel(false);
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const settings = {
    initialSlide: currentImageIndex,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
       {/* Header */}
       {<Header/>}
      {/* Main Content */}

      <Box className="about_section">

<Container>
<Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
Media
    </Typography>
</Container>
</Box>

<Container>
<div className="section_three new_section">
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <div onClick={() => handleImageClick(index)}>
              <CardMedia
                component="img"
                alt={`Image ${index + 1}`}
                height="140"
                image={image.src}
              />
            </div>
          </Grid>
        ))}
      </Grid>

      <Dialog open={showCarousel} onClose={handleCloseCarousel}>
        <DialogContent>
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image.src} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
          <IconButton
            aria-label="previous"
            color="inherit"
            style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)' }}
            onClick={handlePrev}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            aria-label="next"
            color="inherit"
            style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}
            onClick={handleNext}
          >
            <ArrowForwardIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
      </div>
</Container>

     
      {<Footer/>}
    </div>
  );
};

export default App;
