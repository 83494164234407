import {connect} from "react-redux";
import Home from "./Home"
import {getJsonData} from "../../selectors";
import {getJsonData as getJsonDataAction} from "../../actions/index"
import { bindActionCreators } from 'redux';

const stateProps=(state)=>{
    const jsondata=getJsonData(state);
    return {
        jsondata
    }
}
const dispatchToProps = (dispatch)=> bindActionCreators({
    getJsonDataAction
},dispatch)
export default connect(stateProps,dispatchToProps)(Home)
