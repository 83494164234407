// MainPage.js
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import '../../css/style.css';
import Header from '../Common/Header';
import Footer from '../Common/Footer';


const About = () => {
  const [value, setValue] = useState(0);
  const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={6}>{children}</Box>}
    </div>
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box>
      {<Header />}
      <Box className="about_section">
        <Container>
          <Typography className="headingabout" variant="h4" component="h2" sx={{ marginTop: 2 }} style={{ fontFamily: " 'Poppins', sans-serif", fontWeight: "600" }}>
            You are only entitled to the action, never to its fruits
          </Typography>
        </Container>
      </Box>
      {/* Footer */}
      <Box>
        <Container>
          <div className="section_three">
            <Tabs
              className="tabsmain"
              value={value}
              onChange={handleChange}
              indicatorColor="orange" // Customize the active color here
            >
              <Tab label="Shree Ramgiri Baba" className="tabsheading" />
              <Tab label="Nangartas Sansthan " className="tabsheading" />
              <Tab label="Omkareshwar Sansthan" className="tabsheading" />
              <Tab label="Gurukul" className="tabsheading" />
              <Tab label="History" className="tabsheading" />
              <Tab label="Temple" className="tabsheading" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box>
                <Typography variant="h6" className="about_content">
                  Shri Sai Baba is revered as one of the greatest saints ever seen in India, endowed with unprecedented powers, and is worshipped as a God incarnate. (SAI meaning Sakshaat Ishwar) - GOD THE ABSOLUTE
                </Typography>
                <Typography variant="h6" className="about_content">
                  This mysterious Fakir first made his appearance in Shirdi as a youth and remained there throughout his long life. HE transformed the lives of those who met him and continuously is doing so even after his Samadhi in 1918 for those whose hearts are touched by his love and who pray and call him at any emergency in life for his blessings.</Typography>
                <Typography variant="h6" className="about_content">
                  Baba stated that his mission is to “Give Blessings” without discrimination to all, and he proves it in myriad ways by healing the sick, saving lives, protecting the vulnerable, averting accidents, granting offspring, facilitating financial gain, bringing people into harmony within themselves and with each other and, above all, in effecting the spiritual evolution and transformation of those who came to him as the last resort.</Typography>
                <Box className="about_image">
                  <Container >
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a1.png" className="img_about" />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a2.png" className="img_about" />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <img src="/About/a3.png" className="img_about" />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </Box>
                <Box>
                  <Typography variant="h6" className="about_content">
                    Shri Sai Baba is revered as one of the greatest saints ever seen in India, endowed with unprecedented powers, and is worshipped as a God incarnate. (SAI meaning Sakshaat Ishwar) - GOD THE ABSOLUTE
                  </Typography>
                  <Typography variant="h6" className="about_content">
                    This mysterious Fakir first made his appearance in Shirdi as a youth and remained there throughout his long life. HE transformed the lives of those who met him and continuously is doing so even after his Samadhi in 1918 for those whose hearts are touched by his love and who pray and call him at any emergency in life for his blessings.</Typography>
                  <Typography variant="h6" className="about_content">
                    Baba stated that his mission is to “Give Blessings” without discrimination to all, and he proves it in myriad ways by healing the sick, saving lives, protecting the vulnerable, averting accidents, granting offspring, facilitating financial gain, bringing people into harmony within themselves and with each other and, above all, in effecting the spiritual evolution and transformation of those who came to him as the last resort. </Typography>
                </Box>

              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              Content for Tab 2
            </TabPanel>
            <TabPanel value={value} index={2}>
              Content for Tab 3
            </TabPanel>
            <TabPanel value={value} index={3}>
              Content for Tab 4
            </TabPanel>
            <TabPanel value={value} index={4}>
              Content for Tab 5
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Box>
                <Typography variant="h6" className="about_content">
                  Shree Nangartas Sansthan Temple
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Link href="/templedetail">
                    <div className="templeimages">
                      <img src="/About/ganesh.jpg" />
                      <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Shree Ramgiriji Baba Mandir
                      </Typography>
                      <Typography variant="p" className=" ">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                  <img src="/About/ganesh.jpg" />  <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Mahadev Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                   <img src="/About/ganesh.jpg" /> <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Dattatray Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                  <img src="/About/ganesh.jpg" />  <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Shree Shewagiriji Baba            Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
                   
                      <img src="/About/ganesh.jpg" /> <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Hanuman Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div></div>


                  </Grid>
                </Grid>
              </Box>


              <Box className="temple_two">
                <Typography variant="h6" className=" 2">
                  Shree Omkareshwar Santhan Temple        </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">
               
                      <img src="/About/ganesh.jpg" />     <div className="contenttemple">
                      <Typography variant="h6" className=" ">
                        Baba Mandir
                      </Typography>
                      <Typography variant="p" className=" ">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                      </div>

                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">  <img src="/About/ganesh.jpg" />
                    <div className="contenttemple">  <Typography variant="h6" className=" ">
                        Ganpati Mandir     </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div className="templeimages">  <img src="/About/ganesh.jpg" />
                    <div className="contenttemple"><Typography variant="h6" className=" ">
                        Mahadev Mandir        </Typography>
                        <Typography variant="p" className=" ">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </Typography>
                      </div>
                    </div>


                  </Grid>

                </Grid>
              </Box>
            </TabPanel>
          </div>
        </Container>
      </Box>
      {<Footer />}
    </Box>
  );
};

export default About;
