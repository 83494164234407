/* eslint-disable max-lines */
export const asyncSuffixes = {
    ERROR: 'ERROR',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS'
};

const createAsyncTypes = typeString => (
    Object.keys(asyncSuffixes).reduce((acc, suffixKey) => {
        const suffix = asyncSuffixes[suffixKey];

        return {
            [suffix]: `${typeString}_${suffix}`,
            ...acc
        };
    }, {})
);
export const GETJSONDATA = createAsyncTypes("GETJSONDATA");
