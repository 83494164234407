import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import '../../css/style.css';
const Footer = () => {
  return (
    
      <Box
      
      >
        <img src="/Footer/footer.png" className="footermainimg" />
       <Box
         component="footer"
         sx={{
           mt: 'auto',
           backgroundColor: '#470602',
           p: 3,
         }}
       >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
              <div className="linkfooter">
                <Link href="/Termandcondition">Terms & Conditions </Link>
                <Link href="/Generalinformation">General Information</Link>
                <Link href="/Contact">Contact Us</Link>
              </div>
          </Grid>
          <Grid item xs={12} md={5}>
           <div className="socialmedia">
           <Link href="#"><img src="/Footer/facebook.png" /></Link>
           <Link href="#"><img src="/Footer/insta.png" /></Link>
           <Link href="#"><img src="/Footer/youtube.png" /></Link>
           <Link href="#"><img src="/Footer/twitter.png" /></Link>

           </div>
          </Grid>
        
        </Grid>
        <Typography variant="h5" className="copyright">
        © 2022 Shree Ramgiriji Sansthan,Nangartas
        </Typography>
        </Container>
        </Box>
      </Box>
  );
};

export default Footer;
