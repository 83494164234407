import Home from "../components/Home";
import Hometwo from "../components/Home/Hometwo";
import About from "../components/About/About";
import Donation from "../components/Donation/Donation";
import Literature from "../components/Literature/Literature";
import Contact from "../components/Contact/Contact";
import Templedetail from "../components/About/Templedetail";
import Media from "../components/Media/Media";
import Events from "../components/Events/Events";
import Termandcondition from "../components/Termandcondition/Termandcondition";
import Generalinformation from "../components/Generalinformation/Generalinformation";

export const RoutesData = [
    {
        path:"/",
        component:Home,
    },
    {
        path:"/about",
        component:About,
    },
    {
        path:"/donation",
        component:Donation,
    },
    {
        path:"/hometwo",
        component:Hometwo,
    },
    {
        path:"/contact",
        component:Contact,
    },
    {
        path:"/templedetail",
        component:Templedetail,
    },
    {
        path:"/media",
        component:Media,
    },
    {
        path:"/events",
        component:Events,
    },
    {
        path:"/literature",
        component:Literature,
    },
    {
        path:"/termandcondition",
        component:Termandcondition,
    },
    {
        path:"/generalinformation",
        component:Generalinformation,
    },
]

