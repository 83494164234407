import { fromJS } from 'immutable';
import {
GETJSONDATA
} from '../actions/types';
import {setJsonData} from "../selectors";


// eslint-disable-next-line no-unused-vars
const noop = type => () => {
    // debug('no reducer for type %s', type);
};

const initialState = fromJS({ });

const reducer = (state, { type, payload }) => ({  //eslint-disable-line
    [GETJSONDATA.SUCCESS]: () => setJsonData(state, payload),
}[type] || noop(type))() || state;

export default (state = initialState, action) => reducer(state, action);
